import React from 'react';
import { useSwipeable } from 'react-swipeable';
import * as ReactRouter from 'react-router-dom';

function GalleryViewer(props) {
  const params = ReactRouter.useParams();
  const images = props.images;

  const openImage = parseInt(params.id);

  const reelRef = React.useRef(null);
  const photoRef = React.useRef(null);
  const reelActiveItemRef = React.useRef(null);

  const history = ReactRouter.useHistory();


  const doChange = React.useCallback((index)=>{history.push(`/photo/${index}/`)},[history]);
  const doClose = React.useCallback(()=>{
    history.push("/#photos")
  },[history]);

  //Close viewer on click outside of the reel / the photo
  React.useEffect(()=>{
    const listener = (event) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!reelRef.current || reelRef.current.contains(event.target) || !photoRef.current || photoRef.current.contains(event.target)) {
        return;
      }

      //If you got this far, close
      doClose();
    };

      document.addEventListener("click", listener);
      document.addEventListener("touch", listener);

      return () => {
        document.removeEventListener("click", listener);
        document.removeEventListener("touch", listener);
      };

  }, [reelRef, photoRef, doClose]);


  //Scroll reel to active item
  React.useEffect(()=>{
    //console.log("Open Image:",openImage)
    if(reelActiveItemRef.current) {
      const reelActiveItemBounds = reelActiveItemRef.current.getBoundingClientRect();
  
      //console.log(reelRef.current.scrollLeft, reelActiveItemBounds);
      //reelRef.current.scrollTo(reelRef.current.scrollLeft+reelActiveItemBounds.x);
      if(reelRef.current !== null) {
        reelRef.current.scrollTo({
          left:(reelRef.current.scrollLeft-(reelRef.current.clientWidth/2))+(reelActiveItemBounds.left+(reelActiveItemBounds.width/2)),
          behavior: 'smooth'
        });
      }

    }

  },[reelActiveItemRef, openImage])

  const shift = React.useCallback((val)=>{
    let newIndex = openImage+val
    ;
    if(newIndex<0) newIndex=images.length-1;
    else if(newIndex >= images.length) newIndex = 0;

    doChange(newIndex);
  }, [doChange, images, openImage]);


  //Hot keys
  React.useEffect(()=>{
    const keyListener = (event)=>{
      if(event.key==="Escape") {
        doClose();
      }
      else if(event.key==="ArrowLeft") {
        shift(-1);
      }
      else if(event.key==="ArrowRight") {
        shift(+1);
      }
    }

    document.addEventListener("keydown", keyListener);
    return ()=>{
      document.removeEventListener("keydown", keyListener);
    }

  }, [doClose, doChange, images, openImage, shift])

  const swipeHandlers = useSwipeable({
    onSwipedLeft: ()=>{shift(+1)},
    onSwipedRight: ()=>{shift(-1)},
    trackMouse: true,                    // track mouse input
    trackTouch: true
  })

  return (
    <div className="Viewer">
      <div className="toolbar">
        <ReactRouter.Link to="/#photos"><button>×</button></ReactRouter.Link>
      </div>
      <div className="photo">
        <div className="slide">
          <div className="pic" {...swipeHandlers}>
            <img ref={photoRef} src={"/images/"+images[openImage].filename} alt={images[openImage].description} draggable={false}/>
          </div>
        </div>
      </div>
      <div className="reel" ref={reelRef}>
        <ul>
          {images.map((image,index)=><li key={`reel-image-${index}`} ref={index===openImage ? reelActiveItemRef : null} className={index===openImage ? "active" : ""}><ReactRouter.Link to={`/photo/${index}/`}><img src={"/images/thumbs/"+image.filename} alt={image.description}/></ReactRouter.Link></li>)}
        </ul>
      </div>
    </div>
  );
}

export default function Gallery(props) {

  const images = [
    {
      filename: "A+A-1.jpg",
      description: "A beaded wedding dress hangs from a tree, wafting in the breeze"
    },
    {
      filename: "A+A-7.jpg",
      description: "Alison, in a painted silk robe, holds the dress while standing in a red clay parking lot infront of the rental car."
    },
    {
      filename: "A+A-13.jpg",
      description: "Alison, in a painted silk robe, holds the dress while standing in a red clay parking lot while looking off into the horizon."
    },
    {
      filename: "A+A-16.jpg",
      description: "Andrew stands by the open trunk of the jeep, buttoning his left shirt sleeve."
    },
    {
      filename: "A+A-26.jpg",
      description: "Alison, in her wedding dress, tying her hiking boots"
    },
    {
      filename: "A+A-43.jpg",
      description: "Andrew and Alison see eachother in their wedding clothes for the first time."
    },
    {
      filename: "A+A-50.jpg",
      description: "Euta, the officiant, smiles at the camera while holding a conch shell, and two leis "
    },
    {
      filename: "A+A-54.jpg",
      description: "Euta stands with the ocean to his back, barefoot, and infront of a small rug, while blowing into the conch shell."
    },
    {
      filename: "A+A-61.jpg",
      description: "While Euta blows the conch toward the ocean, Andrew and Alison walk into frame holding hands."
    },
    {
      filename: "A+A-68.jpg",
      description: "Holding hands and standing on a small rug, Andrew looks at Alison during the ceremony."
    },
    {
      filename: "A+A-100.jpg",
      description: "Andrew and Alison stand closely together under an umbrella andrew is holding in his left hand. Euta looks at them with his hands held together and speaking."
    },
    {
      filename: "A+A-122.jpg",
      description: "Andrew and Alison look at each other, beaming, while Euta stands next to them smiling as well."
    },
    {
      filename: "A+A-134.jpg",
      description: "Andrew and Alison lean on one another, their forheads touching, while Euta blows the conch. The ocean and clouds behind them."
    },
    {
      filename: "A+A-139.jpg",
      description: "Euta holds paperwork that Andrew is signing while Alison holds onto his left arm."
    },
    {
      filename: "A+A-149.jpg",
      description: "Andrew and Alison stand together on a cliff, the grey-blue ocean crashing on rocks behind and below them."
    },
    {
      filename: "A+A-154.jpg",
      description: "Andrew leans toward Alison's left ear, holding onto her waist. She holds onto his and smiles."
    },
    {
      filename: "A+A-166.jpg",
      description: "A close-up of Andrew and Alison just about to kiss. Their eyes closed."
    },
    {
      filename: "A+A-170.jpg",
      description: "A slightly distant close-up of Andrew and Alison kissing with the grey-blue ocean out of focus in the background."
    },
    {
      filename: "A+A-187.jpg",
      description: "Andrew and Alison stand together in a red clay field with patches of yellow dry grass, mist rolling in, and green shrubs in the further background. They hold hands. Andrew looks at Alison, smiling. Alison looks at Andrew, smiling, while holding the train of her dress up with her left hand."
    },
    {
      filename: "A+A-197.jpg",
      description: "Andrew and Alison hug, Andrew's hands around her waist, her hands around his shoulders, their forheads touching. In the background are more cliffs, and further back the ocean."
    },
    {
      filename: "A+A-208.jpg",
      description: "Andrew and Alison hold hands, looking at one another, smiling, as they walk toward the camera on a red clay path through tall yellow grass."
    },
    {
      filename: "A+A-229.jpg",
      description: "Alison stands alone on a rocky bluff, a feild of yellow grass in the background, and green trees and hills in the further background. She holds her dress up with her left hand, allowing the light to shine through the train. She looks off into the distance to her right."
    },
    {
      filename: "A+A-238.jpg",
      description: "Andrew stands with his hands in the pockets of his dark blue suit, the buttons on the jacket all undone, and smiles while looking toward the right edge of the photo."
    },
    {
      filename: "A+A-239.jpg",
      description: "Andrew and Alison walk away from the camera, downhill, on a path with short yellow grass. Andrew is slightly ahead of Alison, holding her hand."
    },
    {
      filename: "A+A-242.jpg",
      description: "A distant shot with Andrew and Alison in the midframe. They stand in the mowed area of the field, cliffs and the ocean to their left, red clay pathways to their right. They hold hands and stand a little far apart, looking at one another."
    },
    {
      filename: "A+A-243.jpg",
      description: "A distant shot with Andrew and Alison in the midframe. They stand in the mowed area of the field, cliffs and the ocean to their left, red clay pathways to their right. The hold eachother close and kiss."
    }
  ];

  return (
    <div className="Gallery">
      <h1>Photos</h1>
      <ul>
        {images.map((image, index)=><li key={`gallery-image-${index}`}><ReactRouter.Link to={`/photo/${index}/`}><img src={"/images/"+image.filename} alt={image.description}/></ReactRouter.Link></li>)}
      </ul>
      <ReactRouter.Route path="/photo/:id/">
        <GalleryViewer images={images} open={true}/>
      </ReactRouter.Route>
    </div>
  )
}