import React from 'react';

import { BrowserRouter as Router } from 'react-router-dom';
import * as ReactRouter from 'react-router-dom';

import TitleSlide from "./TitleSlide";
import WeddingStory from "./WeddingStory";
import Gallery from "./Gallery";
import Registry from './Registry';
import Thanks from './Thanks';
import EndSlide from './EndSlide';
import Video from './Video';



const Anchor = React.forwardRef((props,ref)=>{
  return <div id={props.id} ref={ref}>{props.children}</div>
})

function WeddingSite() {


  const history = ReactRouter.useHistory();
  const location = ReactRouter.useLocation();

  const thanksRef = React.useRef(null),
        galleryRef = React.useRef(null),
        registryRef = React.useRef(null),
        weddingStoryRef = React.useRef(null),
        videoRef = React.useRef(null);
  

  /*const [modalOpen, setModalOpen] = React.useState(false);*/
  const modalOpen = location.pathname.match(/^\/photos\//);
  const siteRef = React.useRef(null);

  function JumpLink(props) {
    const hash = `#${props.to}`;
    const currentLocation = ReactRouter.useLocation();
    //const history = ReactRouter.useHistory();
  
    return <a 
              href={hash} 
              onClick={(event)=>{
                event.preventDefault();
  
                history.push({ ...currentLocation, hash:hash});
              
                siteRef.current.scrollTo({
                  top: siteRef.current.scrollTop+props.targetRef.current.getBoundingClientRect().top,
                  behavior: 'smooth'}
                )
  
                const waitForScroll = ()=>{
                  //console.log(props.targetRef.current.getBoundingClientRect().top);
                  if(Math.floor(props.targetRef.current.getBoundingClientRect().top)<=0) {
                    //console.log("COMPLETE");
                    siteRef.current.removeEventListener("scroll", waitForScroll);
                    if('onComplete' in props && (typeof props.onComplete === "function")) {
                      props.onComplete(event);
                    }
                  }
                }
  
                siteRef.current.addEventListener("scroll",waitForScroll);
  
                if('onClick' in props && (typeof props.onClick === "function")) {
                  props.onClick(event);
                }
              }}
            >{props.children}</a>
  }

  return (
    <div className={`WeddingSite${modalOpen ? " frozen" : ""}`} ref={siteRef}>
      <TitleSlide />
      <div className="navigation floating">
        <header>
          <h1>Alison &amp; Andrew</h1>
        </header>
        <nav>
          <JumpLink to="wedding" targetRef={weddingStoryRef} >The Wedding</JumpLink>
          <JumpLink to="photos" targetRef={galleryRef} >Photos</JumpLink>
          <JumpLink to="video" targetRef={videoRef} >Video</JumpLink>
          <JumpLink to="registry" targetRef={registryRef} >Registry</JumpLink>
          <JumpLink to="thanks" targetRef={thanksRef} >Thanks</JumpLink>
        </nav>
      </div>
      <main>
        <Anchor id="wedding" ref={weddingStoryRef}/>
          <WeddingStory />
        <Anchor id="photos" ref={galleryRef} />
          <Gallery/>
        <Anchor id="video" ref={videoRef} />
          <Video />
        <Anchor id="registry" ref={registryRef} />
          <Registry />
        <Anchor id="thanks" ref={thanksRef} />
          <Thanks />
      </main>
      <EndSlide />
    </div>
  );
}

export default function SiteRouter() { return (<Router><WeddingSite /></Router>)};
