export default function Registry() {
  return <div className="Registry">
  <h1>Registry</h1>
  <article>
    <p>We’re so grateful for all the love and support. If you’re interested, we created a wedding registry on Blueprint.</p>
    <p>If you have any questions, feel free to contact us.</p>
    <p>Thanks for helping us make our house a home!</p>
    <a className="button" href="https://www.blueprintregistry.com/registry/alisonmarriesandrew" target="_blank" rel="noreferrer">Visit our registry</a>
    
  </article>
</div>;
}