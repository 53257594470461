import React from "react";

export default function TitleSlide() {
  return (
    <div className="TitleSlide">
      <div>
        <header>
          <h1>we eloped!</h1>
          <h2>August 22, 2021 | Maui, Hawaii</h2>
        </header>
      </div>
    </div>
  )
}