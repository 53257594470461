export default function Thanks() {
  return <div className="Thanks">
  <h1>Special thanks to...</h1>
  <main>
  <article>
    <h2>Our Families</h2>
    <p>For supporting us in our constantly changing plans, for being understanding when we told them we were going to elope on vacation, and for keeping our wedding secret long enough for us to announce it!</p>

    <h2>Archie's Care Team</h2>
    <p>Christina, Paul, Maria, Oliver, and Lexie, for taking care of Archie while we were in Hawaii.</p>
  </article>
  <article>
    <h2>Our Team</h2>
    <p>Even though this was the third wedding we’ve planned, we still pulled it together in just over two weeks, which wouldn’t have been possible without the amazing people that helped us put it all together.</p>
    <ul>
      <li><h3>Elope in Hawaii</h3>
      Rodrigo, Michelle, and Caleb<br/>
      Planning, Coordination, Photography, Videography<br/>
      <a href="https://www.elopeinhawaii.com" target="_blank" rel="noreferrer">www.elopeinhawaii.com</a></li>

      <li><h3>Meili Autumn Beauty</h3>
      <a href="https://instagram.com/facebycryslynn" target="_blank" rel="noreferrer">Crystal</a> and <a href="https://www.instagram.com/giovannabeauty/" target="_blank" rel="noreferrer">Giovanna</a><br/>
      Bride &amp; Groom’s hair and makeup.<br/>
      <a href="https://www.meiliautumn.com" target="_blank" rel="noreferrer">www.meiliautumn.com</a></li>

      <li><h3>All Star Cleaners San Mateo</h3>
      Wedding Dress Alteration</li>
    </ul>

  </article>

  </main>
</div>;
}