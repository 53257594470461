export default function WeddingStory() {
  return <div className="WeddingStory">
  <h1>The Wedding</h1>
  <article>
  <p>We've been engaged since January, 2018…</p>
<p>Originally we had planned on getting married on August 8th, 2020. (8-8-2020 has a nice pattern to it.)</p>
<p>But then: Covid!</p>
<p>So, we rescheduled for August 21st, 2021. (8-21-21…still nice!)</p>
<p>But then: the venue went out of business!</p>
<p>At that point we had planned our wedding twice, and decided we&rsquo;d wait to see how things would shake out before we tried again.</p>
<p>In the interim, we planned a vacation for just the two of us in Maui.</p>
<p>As the trip got closer, we were beginning to realize that maybe we wouldn&rsquo;t be able to have the wedding we&rsquo;d been planning anytime soon. &ldquo;Maybe we should just elope while we&rsquo;re in Maui…&rdquo; we joked.</p>
<p>And then, 18 days before we were to leave: &ldquo;Maybe…we should elope while we&rsquo;re in Maui,&rdquo; we agreed.</p>
<p>Excited that it just might work, we set into action planning our wedding for a third time. We lucked out and found a photographer who could coordinate the whole thing. He showed us pictures of a few of his favorite locations, and when we told him which one we really liked, he told us, &ldquo;that location doesn&rsquo;t really have a name. It&rsquo;s a cliff right off the highway,&rdquo; which we thought was fantastic! We already had a lot of the pieces from the first couple times we&rsquo;d planned the wedding–the dress, the rings, a clear sense of what we liked–and we were able to pull together the rest. (Though, Andrew&rsquo;s last minute-decision to get a new suit, to better coordinate with an ocean setting, made for some excitement, as supply chain issues meant he had to piece together a suit from stores in 3 different cities.)</p>
<p>And then, on 8-22-21, we met our officiant on the side of the highway, and walked out to the cliff. During the ceremony, Tropical Storm Linda rolled in, almost like a nod to the Portland weddings we&rsquo;d originally planned. So we exchanged our vows in the rain, on a cliff, on the side of the highway, in Maui.</p>
<p>It was a very different ceremony than we&rsquo;d originally planned, but ultimately it was perfect. Alison married Andrew. And likewise, Andrew married Alison.</p>
<p>We&rsquo;re looking forward to when we can eventually celebrate our marriage with everyone. But for now, it&rsquo;s just the two of us (plus Archie), and we&rsquo;re officially the family we&rsquo;ve been for a long time. And that&rsquo;s pretty great.</p>
  </article>
</div>;
}